import * as React from "react"

const MinusIcon = () => (
  <svg
    viewBox="0 0 512 512"
    className="pc:w-10 sp:w-6 fill-current text-brand-secondary"
  >
    <g>
      <path
        d="M359.77,224.004H152.228c-5.928,0-10.732,4.804-10.732,10.73v42.535c0,5.926,4.805,10.734,10.732,10.734
		H359.77c5.928,0,10.732-4.809,10.732-10.734v-42.535C370.502,228.808,365.697,224.004,359.77,224.004z"
      ></path>
      <path
        d="M256,0C114.613,0,0,114.617,0,256c0,141.387,114.613,256,256,256c141.383,0,256-114.613,256-256
		C512,114.617,397.383,0,256,0z M256,448c-105.871,0-192-86.129-192-192c0-105.867,86.129-192,192-192c105.867,0,192,86.133,192,192
		C448,361.871,361.867,448,256,448z"
      ></path>
    </g>
  </svg>
)

export default MinusIcon
